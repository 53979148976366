<template>
  <v-card elevation="0" class="d-flex flex-column event-Item">
    <router-link :to="to">
      <div class="event-Item_imgBox">
        <v-img
          cover
          class="event-Item_imgBox_img"
          width="100%"
          aspect-ratio="1.777"
          :src="photoUrl"
          :alt="photoName"
        ></v-img>
      </div>
      <div class="event-Item_textBox">
        <h2 class="event-Item_textBox_title text-overflow-ep-2">
          <template v-if="name">{{ name }}</template>
          <template v-else>&emsp;</template>
        </h2>
        <p class="black--text text-overflow-ep-2">
          <template v-if="content" >{{ content }}</template>
          <template v-else>&emsp;</template>
        </p>
      </div>
    </router-link>
  </v-card>
</template>

<script>
import itemMixins from "@/components/page/indexItem.js";
export default {
  mixins: [itemMixins],
  props: {
    type: String
  },
  computed: {
    isMedia() {
      return this.item.is_media
    },
    detailName() {
      if(this.type == 'white-book') return 'white-book-detail'
      if(this.type == 'blog') return this.isMedia ? "media-detail" : "blog-detail";
      return '';
    },
    to() {
      if (!this.item) return "";
      return {
        name: this.detailName,
        params: {
          site_id: this.site_id,
          code: this.code,
        },
      };
    },
  },
};
</script>